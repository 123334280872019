<template>
	<ZyroModal
		max-width="458px"
		max-height="auto"
		height="auto"
		width="auto"
		@close-modal="handleModalClose"
	>
		<div class="edit-store">
			<img
				:src="require(`@/assets/images/store-${modalType}.png`)"
				:alt="title"
				class="edit-store__image"
			>
			<h5 class="z-h5 z-font-weight-normal edit-store__title">
				{{ title }}
			</h5>
			<div class="edit-store__text">
				{{ text }}
				<strong v-if="!hasEcommercePlan && !isUserDesigner">{{ emphasis }}</strong>
			</div>
			<ZyroButton
				v-qa="`builder-storemanager-modal-btn-${btn}`"
				theme="primary"
				color="black"
				class="edit-store__btn"
				size="sm"
				@click="handleBtnClick"
			>
				{{ btn }}
			</ZyroButton>
		</div>
	</ZyroModal>
</template>

<script>
/* eslint-disable global-require */
import {
	mapGetters,
	mapState,
} from 'vuex';

import EventLogApi from '@/api/EventLogApi';
import {
	REDIRECT_PARAM_KEYS,
	REDIRECT_PARAM_VALUES,
} from '@/constants';
import i18n from '@/i18n/setup';
import { STORE_MANAGER_ROUTE } from '@/router';
import {
	mapActionsGui,
	CLOSE_MODAL,
} from '@/store/builder/gui';
import { useRedirects } from '@/use/useRedirects';

export default {
	setup() {
		const { redirectToWWWPayments } = useRedirects();

		return { redirectToWWWPayments };
	},
	computed: {
		...mapState('user', [
			'user',
			'isUserDesigner',
		]),
		...mapGetters('subscription', ['hasEcommercePlan']),
		modalType: ({
			hasEcommercePlan,
			isUserDesigner,
		}) => (hasEcommercePlan || isUserDesigner ? 'manage' : 'upgrade'),
		modalCloseEvent: ({ modalType }) => (`builder.${modalType}_store_modal.close`),
		btnClickEvent: ({ modalType }) => (`builder.${modalType}_store_modal.click_button`),
		title: ({ getTranslation }) => getTranslation('title'),
		text: ({ getTranslation }) => getTranslation('text'),
		emphasis: ({ getTranslation }) => getTranslation('emphasis'),
		btn: ({ getTranslation }) => getTranslation('btn'),
	},
	methods: {
		...mapActionsGui({ closeModal: CLOSE_MODAL }),
		getTranslation(key) {
			return i18n.t(`builder.editOnlineStoreModal.${this.modalType}.${key}`);
		},
		handleModalClose() {
			EventLogApi.logEvent({ eventName: this.modalCloseEvent });
			this.closeModal();
		},
		handleBtnClick() {
			EventLogApi.logEvent({ eventName: this.btnClickEvent });
			if (this.hasEcommercePlan || this.isUserDesigner) {
				this.$router.push({ name: STORE_MANAGER_ROUTE });
				this.closeModal();
			} else {
				this.redirectToWWWPayments({ [REDIRECT_PARAM_KEYS.RETURN]: REDIRECT_PARAM_VALUES.RETURN_STOREFRONT });
			}
		},
	},
};
</script>

<style lang="scss" scoped>
::v-deep .modal .modal__content {
	overflow: visible;
}

.edit-store {
	padding: 62px 4px 32px;
	text-align: center;

	&__image {
		display: inline-block;
		width: 100%;
		margin: 28px 0;
	}

	&__text {
		padding: 4px 0 24px;
		letter-spacing: 0.032em;
	}
}
</style>
